<template>
  <div>
    <v-card>
      <v-card-text>
        <!--begin:Search-->
        <div class="input-group input-group-solid mt-4 mb-4">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <span class="svg-icon svg-icon-lg">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    ></path>
                    <path
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </span>
          </div>
          <input
            v-model="partner_search_key"
            type="text"
            class="form-control py-4 h-auto"
            placeholder="Search by Company Name"
          />
        </div>
      </v-card-text>
      <!--end:Search-->
    </v-card>
    <div
      v-if="$apollo.data.partnerList && partnerList.length > 0"
      class="messages-box"
    >
      <div class="list-group rounded-0">
        <div v-for="(partner, index) in partnerRoomList" :key="index">
          <a
            v-if="partner.id === $route.params.id"
            class="list-group-item list-group-item-action active text-white rounded-0"
          >
            <div class="media">
              <span class="card-icon"
                ><i class="flaticon-users-1 icon-2x text-white"></i
              ></span>
              <div class="media-body ml-4">
                <div
                  class="d-flex align-items-center justify-content-between mb-1"
                >
                  <h6 class="mb-0">
                    <v-badge
                      :content="
                        partner.room
                          ? partner.room._notificationCounts.total
                          : 0
                      "
                      :value="
                        partner.room
                          ? partner.room._notificationCounts.total
                          : 0
                      "
                      color="red"
                    >
                      {{ partner.business_profile.company_name }}
                    </v-badge>
                  </h6>
                  <small
                    v-if="!!partner.room && partner.room.timeline.length > 0"
                    class="small font-weight-bold"
                    >{{
                      partner.room.timeline[partner.room.timeline.length - 1]
                        .event.origin_server_ts | month_format
                    }}</small
                  >
                </div>
                <p
                  v-if="
                    partner.room &&
                      partner.room.timeline.length > 0 &&
                      partner.room.timeline[partner.room.timeline.length - 1]
                        .event.content.body
                  "
                  class="font-italic text-white"
                >
                  {{
                    partner.room.timeline[partner.room.timeline.length - 1]
                      .event.content.body
                  }}
                </p>
              </div>
            </div>
          </a>
          <a
            v-else
            class="list-group-item list-group-item-action list-group-item-light rounded-0"
            @click="$router.push('/supplier/messaging/thread/' + partner.id)"
          >
            <div class="media">
              <span class="card-icon"
                ><i class="flaticon-users-1 icon-2x text-forest"></i
              ></span>
              <div class="media-body ml-4">
                <div
                  class="d-flex align-items-center justify-content-between mb-1"
                >
                  <h6 class="mb-0">
                    <v-badge
                      :content="
                        partner.room
                          ? partner.room._notificationCounts.total
                          : 0
                      "
                      :value="
                        partner.room
                          ? partner.room._notificationCounts.total
                          : 0
                      "
                      color="red"
                    >
                      {{ partner.business_profile.company_name }}
                    </v-badge>
                  </h6>
                  <small
                    v-if="!!partner.room && partner.room.timeline.length > 0"
                    class="small font-weight-bold"
                    >{{
                      partner.room.timeline[partner.room.timeline.length - 1]
                        .event.origin_server_ts | month_format
                    }}</small
                  >
                </div>
                <p
                  v-if="
                    partner.room &&
                      partner.room.timeline.length > 0 &&
                      partner.room.timeline[partner.room.timeline.length - 1]
                        .event.content.body
                  "
                  class="font-italic"
                >
                  {{
                    partner.room.timeline[partner.room.timeline.length - 1]
                      .event.content.body
                  }}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GetCompanyChatInfo from "@/graphql/queries/GetCompanyChatInfo.gql";
import GetChatPartners from "@/graphql/queries/GetChatPartners.gql";
import moment from "moment";

export default {
  filters: {
    month_format: function(value) {
      if (value) {
        return moment(value).format("ll");
      }
    },
  },
  data: function() {
    return {
      partner_search_key: "",
      lastchatlogs: [],
      unreadchatlogs: [],
    };
  },
  apollo: {
    partnerList: {
      query: GetChatPartners,
      variables() {
        return {
          supplier_company: this.$store.state.auth.user.company_relation,
          search:
            this.partner_search_key !== ""
              ? this.partner_search_key
              : undefined,
        };
      },
      update: (data) => data.partnerList.map((p) => p.seller_company),
    },
    userCompany: {
      query: GetCompanyChatInfo,
      variables() {
        return {
          id: this.$store.state.auth.user.company_relation,
        };
      },
      skip() {
        return (
          !this.$store.state.auth.user ||
          !this.$store.state.auth.user.company_relation
        );
      },
      update: (data) => data.company,
    },
  },
  computed: {
    ...mapGetters(["roomsByAlias"]),

    partnerRoomList() {
      if (!this.partnerList || !this.userCompany) {
        return [];
      }
      return this.partnerList.map((p) => ({
        ...p,
        room: this.roomsByAlias[
          "#" +
            p.identifier +
            "<=>" +
            this.userCompany.identifier +
            ":" +
            process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN
        ],
      }));
    },
  },
};
</script>
