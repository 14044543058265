<template>
  <div>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container bg-white">
        <div class="row rounded-lg overflow-hidden shadow">
          <div class="col-lg-5 px-0">
            <div class="bg-white">
              <div class="mb-5">
                <v-dialog v-model="dialog" width="700">
                  <!-- TODO use compose dialog -->
                </v-dialog>
              </div>

              <v-card>
                <v-toolbar flat>
                  <v-toolbar-title class="text-dark">Inboxes</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-tabs v-model="active_tab" horizontal>
                  <v-tab>
                    <v-icon left>mdi-account</v-icon>
                    Partners
                  </v-tab>
                  <v-tab>
                    <v-icon left>mdi-account</v-icon>
                    Public
                  </v-tab>

                  <v-tab-item>
                    <PartnerCompanies />
                  </v-tab-item>
                  <v-tab-item>
                    <PublicCompanies />
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </div>
          </div>
          <!-- Chat Box-->
          <ChatBox class="col-lg-7" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicCompanies from "@/components/chat/PublicCompanies";
import PartnerCompanies from "@/components/chat/PartnerCompanies";
import PrepareRooms from "@/graphql/mutations/PrepareRooms.gql";
import ChatBox from "@/components/chat/ChatBox";
import { mapActions } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  components: {
    PublicCompanies,
    PartnerCompanies,
    ChatBox,
  },
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("hh:mm A MM/DD/YYYY");
      }
    },
    month_format: function(value) {
      if (value) {
        return moment(String(value)).format("ll");
      }
    },
  },
  data() {
    return {
      receiver_email: "",
      unreadchatlogs: [],
      lastchatlogs: [],
      active_tab: 0,
      messages: [],
      active_seller_id: 0,
      seller_companyname: "",
      message: "",
      dialog: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    };
  },
  apollo: {
    // countUnreadChats: {
    //   query: gql`
    //     query CountUnreadChatsQuery($supplier_id: Int!) {
    //       countUnreadChats: countunreadchats(supplier_id: $supplier_id) {
    //         cnt
    //         seller_id
    //         supplier_id
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       supplier_id: parseInt(this.$store.state.auth.user.company_relation),
    //     };
    //   },
    //   client: "chatClient",
    // },
    // lastChats: {
    //   query: gql`
    //     query LastChatsQuery($supplier_id: Int!) {
    //       lastChats: lastchats(supplier_id: $supplier_id) {
    //         id
    //         seller_id
    //         supplier_id
    //         message
    //         sender
    //         date
    //         is_seller
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       supplier_id: parseInt(this.$store.state.auth.user.company_relation),
    //     };
    //   },
    //   client: "chatClient",
    // },
  },

  watch: {
    $route() {
      if (this.$route.params.id != "" && this.$route.params.id != undefined) {
        this.active_seller_id = this.$route.params.id;
        // this.updateCompanyName();
        // this.scrollToEnd();
        // this.setReadMessage();
        // see if room between these to companies exists,
      }
    },
  },
  mounted() {
    this.$apollo.mutate({ mutation: PrepareRooms });
    // todo pass rooms to public and partner companies
    if (this.$route.params.id != "" && this.$route.params.id != undefined) {
      this.active_seller_id = this.$route.params.id;
    }
    setTimeout(() => {
      this.getContactInfo({
        supplier_company: this.$store.state.auth.user.company_relation,
        seller_company: this.active_seller_id,
      }).then((contact) => {
        if (contact.data.length == 0) {
          return;
        } else {
          if (contact.data[0].status == "public") {
            this.active_tab = 1;
          } else {
            this.active_tab = 0;
          }
        }
      });
    }, 1000);
  },
  methods: {
    ...mapActions([
      "getContactInfo",
      "getContactTotalCount",
      "submitContact",
      "updateContact",
      "getCompanyInfo",
      "getApplicationInfo",
      "submitApplication",
    ]),
    async setReadMessage() {
      if (this.active_seller_id != 0) {
        // await this.$apollo.mutate({
        //   client: "chatClient",
        //   mutation: gql`
        //     mutation UpdateReadMutation($seller_id: Int!, $supplier_id: Int!) {
        //       updateRead(seller_id: $seller_id, supplier_id: $supplier_id) {
        //         seller_id
        //         supplier_id
        //       }
        //     }
        //   `,
        //   variables: {
        //     supplier_id: parseInt(this.$store.state.auth.user.company_relation),
        //     seller_id: parseInt(this.active_seller_id),
        //   },
        // });
        this.updateCountChats();
      }
    },
  },
};
</script>
<style scoped>
.text-forest {
  color: #455a64 !important;
}
.blue-grey {
  background-color: #455a64 !important;
  border-color: #455a64 !important;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #455a64;
  border-color: #455a64;
}
</style>
