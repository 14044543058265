<template>
  <div
    v-if="
      $route.params.id &&
        $apollo.data.selectedCompany &&
        $apollo.data.userCompany
    "
    @click="setReadMessage"
  >
    <v-toolbar class="blue-grey darken-2">
      <v-toolbar-title class="text-white">{{
        buyerCompanyName
      }}</v-toolbar-title>
      <!-- <v-spacer></v-spacer> -->
      <!-- <v-btn color="primary"> View Profile        </v-btn> -->
    </v-toolbar>

    <v-card
      v-if="selectedRoom && messageEvents"
      id="chat_div"
      style="max-height: 60vh; overflow-y: scroll;"
      @scroll.native="handleScroll"
    >
      <div v-for="(chat, id) in messageEvents" :key="id">
        <!-- Reciever Message-->
        <div
          v-if="chat.sender.userId === matrixUserId"
          class="media w-50 ml-auto mb-3"
        >
          <div class="media-body">
            <!-- <p class="medium ">{{ userCompany.company_name }}</p> -->
            <div class="blue-grey rounded py-2 px-3 mb-2">
              <p class="text-small mb-0 text-white">
                {{ chat.event.content.body }}
              </p>
            </div>
            <p class="medium ">
              {{ userCompany.company_name }}
              <span class="text-muted">
                {{ chat.event.origin_server_ts | date_format }}
              </span>
            </p>
          </div>
        </div>

        <!-- Sender Message-->
        <template v-else>
          <div class="media ml-3 w-50 mb-3">
            <span class="card-icon"
              ><i class="flaticon-users-1 icon-2x text-forest"></i
            ></span>
            <div class="media-body">
              <!-- <p class="medium ">
                {{ selectedCompany.business_profile.business_profile }}
              </p> -->
              <div class="bg-light rounded py-2 px-3 mb-2">
                <p class="text-small mb-0 ">{{ chat.event.content.body }}</p>
              </div>
              <p class="medium ">
                {{ buyerCompanyName }}
                <span class="text-muted">
                  {{ chat.event.origin_server_ts | date_format }}
                </span>
              </p>
            </div>
          </div>
        </template>
      </div>
    </v-card>
    <v-card class="mx-3 mt-3">
      <v-card-text>
        <!-- Typing area -->
        <div class="bg-white">
          <div class="input-group mt-3">
            <i class="flaticon2-file icon-lg"></i>
            <input
              v-model="message"
              type="text"
              placeholder="Type a message"
              aria-describedby="button-addon2"
              class="form-control rounded-0 border-0 py-4 bg-white"
              @keyup.enter="sendMessage"
            />
            <div class="input-group-append">
              <button
                id="button-addon2"
                class="btn btn-link"
                @click="sendMessage"
              >
                <i class="fa fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import GetCompanyChatInfo from "@/graphql/queries/GetCompanyChatInfo.gql";
import CreateMatrixRoom from "@/graphql/mutations/CreateMatrixRoom.gql";
import { matrixClient } from "@/core/services/matrix";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(value).format("hh:mm A MM/DD/YYYY");
      }
    },
    month_format: function(value) {
      if (value) {
        return moment(value).format("ll");
      }
    },
  },
  data: function() {
    return {
      message: [],
      messages: [],
    };
  },
  apollo: {
    selectedCompany: {
      query: GetCompanyChatInfo,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      update: (data) => data.company,
    },
    userCompany: {
      query: GetCompanyChatInfo,
      variables() {
        return {
          id: this.$store.state.auth.user.company_relation,
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      update: (data) => data.company,
    },
  },
  computed: {
    ...mapGetters(["roomsByAlias"]),
    selectedRoom() {
      if (
        !this.$route.params.id ||
        !this.userCompany ||
        !this.selectedCompany
      ) {
        return null;
      }
      const roomAlias =
        "#" +
        this.selectedCompany.identifier +
        "<=>" +
        this.userCompany.identifier +
        ":" +
        process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN;
      return this.roomsByAlias[roomAlias];
    },
    buyerCompanyName() {
      if (!this.selectedCompany) {
        return "";
      }
      return this.selectedCompany.business_profile.company_name;
    },
    matrixUserId() {
      return `@goose${this.$store.state.auth.user.id}:${process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN}`;
    },
    messageEvents() {
      return this.selectedRoom
        .getLiveTimeline()
        .getEvents()
        .filter((t) => t.event.content.body);
    },
    readyToScroll() {
      const isReady =
        !!this.$route.params.id &&
        !!this.$apollo.data.selectedCompany &&
        !!this.$apollo.data.userCompany &&
        !!this.selectedRoom &&
        this.messageEvents.length > 0;

      return isReady
        ? { isReady, length: this.messageEvents.length }
        : { isReady };
    },
  },
  watch: {
    readyToScroll: {
      immediate: true,
      handler(value) {
        // default timeline has at most 8 events
        // only scroll to bottom for default timeline
        if (value.isReady && value.length <= 8) {
          this.$nextTick(() => {
            this.scrollToEnd();
          });
        }
      },
    },
  },
  methods: {
    async handleScroll({ target }) {
      let topOfWindow = target.scrollTop === 0;

      if (topOfWindow && this.selectedRoom) {
        await matrixClient.scrollback(this.selectedRoom, 10);
      }
    },
    async sendMessage() {
      // create room first if needed
      if (!this.selectedRoom) {
        const createdRoom = await this.createRoom();
        await this.$store.dispatch("getRooms");
        const response = await this.sendFullMessage(createdRoom.room_id);

        this.message = "";
        this.scrollToEnd();
      } else {
        const response = await this.sendFullMessage(
          this.selectedRoom.summary.roomId
        );
        this.message = "";
        this.scrollToEnd();
      }

      // console.log(response);
      // other wise send messeage
    },
    async sendFullMessage(roomId) {
      const content = {
        body: this.message,
        msgtype: "m.text",
      };
      return await matrixClient.sendEvent(
        roomId,
        "m.room.message",
        content,
        ""
      );
    },

    async createRoom() {
      // create room
      const buyerCompanyMatrixUsers = this.selectedCompany.company_users.map(
        (u) => `@goose${u.id}:${process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN}`
      );
      const userCompanyMatrixUsers = this.userCompany.company_users
        .filter(
          (u) => u.id.toString() !== this.$store.state.auth.user.id.toString()
        )
        .map(
          (u) => `@goose${u.id}:${process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN}`
        );

      const userMatrixId = `@goose${this.$store.state.auth.user.id}:${process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN}`;

      const buyerMatrixCompany = `@goosecompany${this.selectedCompany.id}:${process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN}`;
      const userMatrixCompany = `@goosecompany${this.userCompany.id}:${process.env.VUE_APP_MATRIX_URL_BASE_DOMAIN}`;
      // need to create a room that can be found later by using the identifiers.
      // it is always buyer first then seller.
      const roomName =
        this.buyerCompanyName +
        " & " +
        this.userCompany.profile.profile_company_name;
      const roomAlias =
        this.selectedCompany.identifier + "<=>" + this.userCompany.identifier;

      const {
        data: { createRoomAndJoin },
      } = await this.$apollo.mutate({
        mutation: CreateMatrixRoom,
        variables: {
          data: {
            userMatrixId,
            roomName,
            roomAlias,
            matrixUsers: [
              ...buyerCompanyMatrixUsers,
              ...userCompanyMatrixUsers,
            ],
            matrixCompanies: [buyerMatrixCompany, userMatrixCompany],
          },
        },
      });
      // object that has room id as field
      return createRoomAndJoin;
    },
    scrollToEnd() {
      var chat_div = document.querySelector("#chat_div");
      if (chat_div != null) {
        chat_div.scrollTop = chat_div.scrollHeight;
      }
    },
    // could also be done as soon as selected room has a value
    async setReadMessage() {
      if (this.selectedRoom && this.selectedRoom.timeline.length > 0) {
        matrixClient.sendReadReceipt(
          this.selectedRoom.timeline[this.selectedRoom.timeline.length - 1]
        );
      }
    },
  },
};
</script>
